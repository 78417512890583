<template>
  <div class="landing-page-header">
    <div class="container">
      <div class="row">
        <div
          class="landing-page-header__max-content landing-page-header__no-right-padding"
        >
          <p class="fs-2 mb-0 lh-1">{{ preTitle }}</p>
          <h1 class="mb-0 fw-bold text-transform-none">{{ title }}</h1>
        </div>
        <div
          v-if="isDiscountedGame"
          class="landing-page-header__max-content landing-page-header__title-discount-styling"
        >
          25% OFF
        </div>
      </div>

      <div class="landing-page-header__bar">
        <p class="m-0 fw-bold">
          <landing-page-product-header-text
            v-if="startingPriceExists"
            :starting="starting"
            :product-key="productKey"
          />
        </p>

        <!-- order now button only exists for xbox platform -->
        <a
          v-if="platform?.toLowerCase() === StrapiPlatform.XBox.toLowerCase()"
          :href="orderURL"
          class="landing-page-header__order-now btn btn-primary btn-lg px-2 fs-tiny mt-2"
          :rel="microsoftStore ? ' noopener' : undefined"
          :target="microsoftStore ? '_blank' : undefined"
          data-track="landing-page-order-now"
        >
          {{
            preOrder ? $t('frontend/preorder-now') : $t('frontend/order-now')
          }}
        </a>
      </div>

      <!-- we need a wrapper to observe the visibilty in the page while scrolling -->
      <div ref="headerBar" class="landing-page-header__bar-wrapper">
        <transition name="slide">
          <div
            v-if="isSticky"
            class="landing-page-header__bar landing-page-header__bar-popout py-4"
          >
            <div
              class="d-flex align-items-start align-items-md-center container"
            >
              <a
                :href="orderURL"
                class="landing-page-header__order-now btn btn-primary btn-lg px-2 fs-tiny me-2 me-lg-4"
                :rel="microsoftStore ? ' noopener' : undefined"
                :target="microsoftStore ? '_blank' : undefined"
                data-track="landing-page-order-now"
              >
                {{
                  preOrder
                    ? $t('frontend/preorder-now')
                    : $t('frontend/order-now')
                }}
              </a>
              <p class="mb-0 fw-bold">
                {{ title }}
                <br />
                <landing-page-product-header-text
                  v-if="startingPriceExists"
                  :starting="starting"
                  :product-key="productKey"
                />
              </p>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Money } from 'gportal-grpc/gportal/type/v1/money_pb';
import {
  StrapiPlatform,
  type StrapiProductStartConfiguration,
  StrapiProductType,
} from '~/apollo/types/types';
import { DISCOUNTED_PRODUCT_KEYS } from '~/components/strapi/constants';

const STICKY_THRESHOLD_PX = 16;

export default defineComponent({
  name: 'StrapiProductHeader',
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    preTitle: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    orderUrl: {
      type: String as PropType<string>,
      required: true,
    },
    productKey: {
      type: String as PropType<string>,
      required: true,
    },
    productType: {
      type: String as PropType<string>,
      required: true,
    },
    preOrder: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    microsoftStore: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    platform: {
      type: String as PropType<StrapiProductType>,
      required: false,
      default: null,
    },
    starting: {
      type: Object as PropType<StrapiProductStartConfiguration>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const orderURL = computed<string>(() => {
      if (props.microsoftStore) {
        return 'https://www.microsoft.com/en-us/p/gportal-game-servers/9nm39brp1dd2';
      }

      return props.orderUrl;
    });

    const isDiscountedGame = computed<boolean>(() =>
      DISCOUNTED_PRODUCT_KEYS.includes(props.productKey),
    );

    // scroll behaviour
    const headerBar = ref<HTMLElement>();
    const { y } = useScroll(window);
    const isSticky = ref<boolean>(false);
    const ready = ref<boolean>(false);
    watch(
      () => y.value,
      (newValue) => {
        if (headerBar.value == null) return;

        if (newValue >= headerBar.value.offsetTop + STICKY_THRESHOLD_PX) {
          // is on top of viewport -> apply sticky class
          isSticky.value = true;
        } else {
          // is in viewport
          isSticky.value = false;
        }
      },
    );

    onMounted(() => {
      ready.value = true;

      if (y.value >= headerBar.value.offsetTop + STICKY_THRESHOLD_PX) {
        isSticky.value = true;
      }
    });

    const startingPriceExists = computed<boolean>(
      () => props.starting && props.starting.prices.length > 0,
    );

    return {
      orderURL,
      headerBar,
      isSticky,
      ready,
      isDiscountedGame,
      startingPriceExists,
      StrapiPlatform,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import 'gportal-theme/scss/colors.scss';
@import '~/assets/styles/gportal/variables';

.landing-page-header {
  &__bar {
    font-size: 0.85rem !important;

    &-popout {
      background-color: $gp-midnight-1;
      position: fixed;
      left: 0;
      right: 0;
      z-index: 99;
      top: $mobile-bar-height;

      @include media-breakpoint-up(xl) {
        top: 0;
        left: $desktop-left-offset;
      }
    }

    @include media-breakpoint-up(sm) {
      font-size: 1rem !important;
    }
  }

  &__order-now {
    min-width: 10rem;
  }

  &__max-content {
    max-width: max-content;
  }

  &__no-right-padding {
    padding-right: 0;
  }

  &__title-discount-styling {
    line-height: 2;
    padding: 8px 5px;
    font-size: 1rem;
    font-weight: bolder;
    border-radius: 10px;
    background: #a92738cf;
    margin-left: calc(var(--bs-gutter-x) * 0.5);
    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
      padding: 10px 30px;
    }
  }
}

.slide {
  &-enter-active {
    transition: transform 0.3s ease-in-out;
  }

  &-leave-active {
    transition: transform 0.3s ease-in-out;
  }

  &-enter-from,
  &-leave-to {
    transform: translateY(-100%);
  }
}
</style>
